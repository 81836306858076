/** @jsx jsx **/
import { Container, jsx, Text, Heading, Box, Grid, Button, Flex, Spinner } from 'theme-ui'
import { FC, useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import Layout from '~/components/layout'
import MarkdownIt from '~/components/MarkdownIt'
import theme from '~/gatsby-plugin-theme-ui'
import Dash from '~/components/dash'
import { GatsbyImageType } from '~/@types/models'
import HeadingV2 from '~/componentsV2/heading'

type Props = {}

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
      rugs: {
        color: {
          title: string
          options: Array<{
            description: string
            image: GatsbyImageType
            tag: string
            title: string
          }>
        }
        pattern: {
          title: string
          options: Array<{
            description: string
            image: GatsbyImageType
            tag: string
            title: string
          }>
        }
        style: {
          title: string
          options: Array<{
            description: string
            tag: string
            image: GatsbyImageType
            title: string
          }>
        }
      }
    }
    html: string
  }
}

const RugFinderPage: FC<Props> = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "rugFinder" } }) {
        frontmatter {
          title
          rugs {
            color {
              title
              options {
                description
                image {
                  ...heroImage
                }
                tag
                title
              }
            }
            pattern {
              title
              options {
                description
                image {
                  ...heroImage
                }
                tag
                title
              }
            }
            style {
              title
              options {
                description
                tag
                image {
                  ...heroImage
                }
                title
              }
            }
          }
        }
        html
      }
    }
  `)
  const {
    frontmatter: { title, rugs },
    html: content,
  } = query.pageData
  const contentRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (contentRef.current) contentRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Box my={[theme.space.l, null, theme.space.xl2]}>
        <Container
          sx={{
            mb: [theme.space.l, null, theme.space.xl],
          }}
        >
          <HeadingV2
            sx={{
              textAlign: ['center', null, null, 'left'],
              fontSize: '2rem !important',
            }}
          >
            {title}
          </HeadingV2>
          <Text
            ref={contentRef}
            id="rug-finder-content"
            variant="regular"
            sx={{
              maxWidth: 620,
            }}
          >
            <MarkdownIt content={content} />
          </Text>
        </Container>
        <Container
          sx={{
            maxWidth: 1340,
          }}
        >
          <Steps steps={rugs} handleScroll={handleScroll} />
        </Container>
      </Box>
    </Layout>
  )
}

type StepsProps = {
  steps: QueryResult['pageData']['frontmatter']['rugs']
  handleScroll(): void
}

const Steps: FC<StepsProps> = ({ steps, handleScroll }) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const defaultSelectValue = [
    {
      name: 'style',
      value: '',
    },
    {
      name: 'color',
      value: '',
    },
    {
      name: 'pattern',
      value: '',
    },
  ]
  const [selectedValue, setSelectedValue] = useState(defaultSelectValue)
  const stepsBase = [
    {
      title: steps.style.title,
      type: 'style',
      options: steps.style.options,
    },
    {
      title: steps.color.title,
      type: 'color',
      options: steps.color.options,
    },
    {
      title: steps.pattern.title,
      type: 'pattern',
      options: steps.pattern.options,
    },
  ]
  const stepsCount = stepsBase.length

  useEffect(() => {
    if (currentStep > stepsCount) {
      setTimeout(() => {
        navigate(
          `/collections/rug-finder/?style=${selectedValue[0].value}&color=${selectedValue[1].value}&pattern=${selectedValue[2].value}`
        )
        setCurrentStep(0)
        setSelectedValue(defaultSelectValue)
      }, 2000)
    }
  }, [selectedValue])

  if (currentStep > stepsCount) {
    return (
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Spinner
          sx={{
            mr: theme.space.md,
          }}
        />
        Searching for rugs...
      </Flex>
    )
  }

  return (
    <Box>
      <Heading variant="title4" mb={[theme.space.md1, null, theme.space.md1]}>
        {stepsBase[currentStep - 1].title}
      </Heading>
      <Box sx={{ mb: [theme.space.md2, null, theme.space.l] }}>
        <Flex
          sx={{
            height: 34,
            justifyContent: 'flex-end',
          }}
        >
          {currentStep > 1 && (
            <Button
              variant="noStyle"
              onClick={() => {
                setCurrentStep(prevState => prevState - 1)
              }}
            >
              <Text
                variant="small"
                sx={{
                  color: 'muted',
                }}
              >
                Back
              </Text>
            </Button>
          )}
        </Flex>
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Text
            variant="small"
            sx={{
              color: 'muted',
              whiteSpace: 'nowrap',
              mr: theme.space.md,
            }}
          >
            {currentStep} of {stepsCount}
          </Text>
          <Box
            sx={{
              bg: 'bg',
              width: '100%',
              height: '2px',
              position: 'relative',
              '::before': {
                content: '""',
                display: 'block',
                top: 0,
                left: 0,
                position: 'absolute',
                bg: 'text',
                width: `${currentStep * (1 / 3) * 100}%`,
                transition: 'all .3s',
                height: '100%',
              },
            }}
          />
        </Flex>
      </Box>

      {stepsBase.map(
        (step, index) =>
          currentStep === index + 1 && (
            <Grid key={step.title} columns={[1, 2, 3]} gap={[0, null, theme.space.gridGap3]}>
              {step.options.map(option => (
                <Box key={option.title}>
                  <Box
                    sx={{
                      mb: [theme.space.md2, null, theme.space.l],
                    }}
                  >
                    <GatsbyImage fluid={option.image.childImageSharp.fluid} />
                  </Box>
                  <Heading variant="title6" mb={theme.space.sm1}>
                    {option.title}
                  </Heading>
                  {option.description && option.description !== '-' && (
                    <Text
                      variant="micro"
                      sx={{
                        maxWidth: 300,
                        color: theme.colors.muted,
                        mb: theme.space.md1,
                      }}
                    >
                      {option.description}
                    </Text>
                  )}
                  <Button
                    variant="noStyle"
                    sx={{
                      mb: [theme.space.l1, null, theme.space.l3],
                    }}
                    onClick={() => {
                      setCurrentStep(prevState => prevState + 1)
                      setSelectedValue(prevState => {
                        const newState = prevState.map((s, i) =>
                          i === index ? { ...s, value: option.tag } : s
                        )
                        return newState
                      })
                      handleScroll()
                    }}
                  >
                    <Flex
                      as="span"
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        as="span"
                        variant="articleButton"
                        sx={{
                          color: theme.colors.text,
                          pr: theme.space.md,
                        }}
                      >
                        Select
                      </Text>
                      <Dash />
                    </Flex>
                  </Button>
                </Box>
              ))}
            </Grid>
          )
      )}
    </Box>
  )
}

export default RugFinderPage
